import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { UserListHead } from "src/sections/@dashboard/user";


const ScheduledReportGridHead = ({ scheduledList, order, orderBy, handleRequestSort }) => {
    const getTableDesk = [
        { id: 'scheduled', label: 'Scheduled on', alignRight: false, hasSort: true },
        { id: 'subject', label: 'Subject', alignRight: false, hasSort: true },
        { id: 'data_range', label: 'Data range', alignRight: false },
        { id: 'frequency', label: 'Frequency', alignRight: false, hasSort: true },
        { id: 'group_by', label: 'Group Data By ', alignRight: false, hasSort: true },
        { id: 'file_type', label: 'File Type', alignRight: false },
        { id: 'recipient', label: 'Recipient', alignRight: false },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const getTableMobile = [
        { id: 'scheduled-on', label: 'Scheduled On', alignRight: false, hasSort: true },
        { id: 'post-content', label: 'post-content', alignRight: false, hasSort: true },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;


    return (
        <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={scheduledList.length}
            onRequestSort={handleRequestSort}
            style={{ whiteSpace: 'nowrap' }}
            isShowCheckbox={false}
        />
    )
}

export default ScheduledReportGridHead;