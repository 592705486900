import { CommonHelper } from 'src/utils/commonHelper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';
import SvgColor from '../../../components/svg-color';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import NotificationsIcon from '@mui/icons-material/Notifications';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const navSidebar = () => {
  return [
    {
      title: 'Report Stats',
      menu: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: icon('dashboard'),
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Social Media Report',
          path: '/report',
          icon: icon('integration'),
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Scheduled Reports ',
          path: '/scheduled-report-view',
          icon: icon('scheduled-post'),
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
      ]
    },
    {
      title: 'Post Stats',
      menu: [
        {
          title: 'Published Post',
          path: '/published-post',
          icon: icon('published-post'),
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Social Inbox',
          path: '/message',
          icon: icon('message'),
          permissions: `${CommonHelper.PermissionList.MANAGE_SOCIAL_INBOX}`,
        },
      ]
    },
    {
      title: 'Schedule Posts',
      menu: [
        {
          title: 'New Post',
          path: '/new-post',
          icon: icon('new-post'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
        {
          title: 'Upcoming Post',
          path: '/upcoming-post',
          icon: icon('scheduled-post'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
        {
          title: 'Calendar',
          path: '/calendar',
          icon: icon('calendar'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
        {
          title: 'Approval',
          path: '/approval',
          icon: icon('approval'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
      ]
    },
    {
      title: 'Templates',
      menu: [
        {
          title: 'Draft',
          path: '/draft',
          icon: icon('draft'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
        {
          title: 'Media Library',
          path: '/media-library',
          icon: icon('media'),
          permissions: `${CommonHelper.PermissionList.PUBLISH_PERMISSION}`,
        },
      ]
    },
    {
      title: 'Integration',
      menu: [
        {
          title: 'Integration',
          path: '/integration',
          icon: icon('integration'),
          permissions: `${CommonHelper.PermissionList.INTEGRATE_PERMISSION}`,
        },
      ]
    },
  ];
};

export const getSettingsMenu = () => {
  return [
    {
      title: "",
      menu: [
        {
          title: 'Profile',
          path: '/profile',
          icon: <AccountCircleIcon />,
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Manage Brand',
          path: '/manage-brand',
          icon: <BuildIcon />, // You can choose a different icon if needed
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Manage Organization',
          path: '/manage-organization',
          icon: <BusinessIcon />,
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        },
        {
          title: 'Manage Billing',
          path: '/billing',
          icon: <PaymentIcon />,
          permissions: `${CommonHelper.PermissionList.ORG_ADMIN}`,
          portalAdmin:true,
        },
        {
          title: 'Branding',
          path: '/branding',
          icon: <BrandingWatermarkIcon />,
          permissions: `${CommonHelper.PermissionList.ORG_ADMIN}`,
        },
        {
          title: 'Notification Settings',
          path: '/notification-settings',
          icon: <NotificationsIcon />,
          permissions: `${CommonHelper.PermissionList.READ_POST}`,
        }
      ]
    }]
};

export const navMobilebar = () => {
  return [
    { title: 'Published Post', path: '/published-post', icon: icon('published-post') },
    { title: 'New Post', path: '/new-post', icon: icon('new-post') },
    { title: 'Calendar', path: '/calendar', icon: icon('calendar') },
    { title: 'Integration', path: '/integration', icon: icon('integration') },
  ];
};
